<template>
    <div>
        <div class="columns is-vcentered" style="display:none">
            <div class="column is-child is-4 is-offset-2">
                <a v-bind:href="lionLoginUrl" class="button is-primary is-centered is-fullwidth">
                    Continue with Lion Login
                </a>
            </div>
            <div class="column is-child is-4" v-if="!showEmailPassword">
                <input type="button"
                    @click="showEmailPassword=true"
                    class="level-item button is-primary is-fullwidth"
                    value="I don't have a Lion Login"/>
            </div>
            <div class="column is-child is-4" v-if="showEmailPassword">
                <p class="control has-icons-right">
                    <input class="input has-icons-right" type="text" v-model="email" placeholder="Enter your email"/>
                    <span class="icon is-small is-right">
                        <i class="fas fa-user"></i>
                    </span>
                </p>
                <p class="control has-icons-right">
                    <input class="input has-icons-right" type="password" v-model="password" placeholder="Password"/>
                    <span class="icon is-small is-right">
                        <i class="fas fa-lock"></i>
                    </span>
                </p>
                <p class="control has-icons-right" v-if="pinFingerprint">
                    <input class="input has-icons-right" type="text" v-model="pin" placeholder="Out of Office PIN"/>
                    <span class="icon is-small is-right">
                        <i class="fas fa-shield-alt"></i>
                    </span>
                </p>
                <input type="button" @click="login()" class="level-item button is-primary is-outlined is-fullwidth" value="Login"/>
            </div>
        </div>

        <div class="login-panel">
            <div class="login-block">
                <img :src="require('@/assets/publicis.png')" alt="Publicis Lion">
                 <p class="inline-text">
                    If you have a Lion Login account please click on the button below to access Conversability
                </p>
                <a v-bind:href="lionLoginUrl" class="button is-primary is-centered is-fullwidth">
                    Continue with Lion Login
                </a>
            </div>
<!--
            <div class="vertical-seperator">
                <div class="vertical-line"></div>
                <div>OR</div>
                <div class="vertical-line"></div>
            </div>
            <div class="login-block">
                <div v-if="!showEmailPassword">
                    <img :src="require('@/assets/Conversability_logo_200.png')" alt="Conversability Logo" class="heavy-icon">
                    <p style="">
                        If you <u>don't have</u> a Lion Login account please log into Conversability below
                    </p>
                    <input type="button"
                        @click="showEmailPassword=true"
                        class="level-item button is-primary is-fullwidth"
                        value="I don't have a Lion Login"/>
                </div>
                <div v-if="showEmailPassword">
                    <p class="control has-icons-right">
                        <input class="input has-icons-right" type="text" v-model="email" placeholder="Enter your email"/>
                        <span class="icon is-small is-right">
                            <i class="fas fa-user"></i>
                        </span>
                    </p>
                    <p class="control has-icons-right">
                        <input class="input has-icons-right" type="password" v-model="password" placeholder="Password"/>
                        <span class="icon is-small is-right">
                            <i class="fas fa-lock"></i>
                        </span>
                    </p>
                    <p class="control has-icons-right" v-if="pinFingerprint">
                        <input class="input has-icons-right" type="text" v-model="pin" placeholder="Out of Office PIN"/>
                        <span class="icon is-small is-right">
                            <i class="fas fa-shield-alt"></i>
                        </span>
                    </p>
                    <input type="button" @click="login()" class="level-item button is-primary is-outlined is-fullwidth" value="Login"/>

                    <div class="forgotten-password-container">
                        <a href="/forgottenpassword"> Forgot Password </a>
                    </div>

                    <div class="notification-container" v-if="message">
                        <p class="has-icons-right notification is-warning">
                            <i class="fas fa-exclamation-triangle"></i> {{message}}
                        </p>
                    </div>
                </div>
            </div>
-->
        </div>

        <div class="has-text-centered">
                <img :src="require('@/assets/pm-logo.png')" alt="Publicis Media Logo"/>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import { EventBus } from '@/event-bus';
import ability from '@/ability.js';

export default {
    name: 'Login',
    data () {
        return {
            loginUrl: process.env.VUE_APP_INDIGO_API_URL + '/login',
            nextUrl: (this.$route.params && this.$route.params.nextUrl) || '/',
            email: null,
            password: null,
            message: null,
            showEmailPassword: false,
            pinFingerprint: null,
            pin: null
        };
    },
    computed: {
        lionLoginUrl () {
            if (this.nextUrl === '/') {
                return process.env.VUE_APP_INDIGO_API_URL +
                    '/lionloginredirect';
            } else {
                return process.env.VUE_APP_INDIGO_API_URL +
                    `/lionloginredirect?returnUrl=${encodeURIComponent(this.nextUrl)}`;
            }
        }
    },
    created () {
        window.sessionStorage.setItem('nextUrl', this.nextUrl);
    },
    methods: {
        login () {
            const loginBody = { email: this.email, password: this.password };
            if (this.pinFingerprint) {
                loginBody.pin = this.pin;
                loginBody.pinFingerprint = this.pinFingerprint;
            }
            axios.post(this.loginUrl, loginBody)
                .then(response => {
                    localStorage.setItem('jwt', response.data.token);
                    localStorage.setItem('userId', response.data.userId);
                    localStorage.setItem('email', response.data.email);
                    localStorage.setItem('role', response.data.role);
                    // todo: update abilities for appropriate role after login
                    this.$ability.update(ability[response.data.role].rules);

                    if (localStorage.getItem('jwt') !== null) {
                        EventBus.$emit('loggedIn', true);
                        this.$router.push(this.nextUrl);
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data.message) {
                        this.message = error.response.data.message;
                        if (error.response.data.isPinRequired) {
                            this.pinFingerprint = error.response.data.pinFingerprint;
                        }
                    } else {
                        this.message = 'Unexpected error';
                    }
                });
        }
    }
};
</script>

<style lang="scss">
.tile.is-ancestor {
    flex-wrap:wrap;
}
p.control {
    padding-bottom: 10px;
}
.login-panel {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    margin-bottom: 4em;
    text-align: center;
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19 */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
    align-items:center;
    padding: 20px;
    background: $panel-background;
    border-radius: 15px;
    max-width: 900px;

    p {
        min-height: 80px;
    }
}

.vertical-seperator {
    display: inline-block;
    text-align: center;
    width: 30px;
    margin: auto 10px;
    font-weight:bold;
    font-size:20px;

    div{
        margin-bottom:5px;
    }

    .vertical-line{
        display: inline-block;
        margin: 5px;
        border-left: 1px $primary solid;
        height: 170px;
    }
}

.login-block{
    width: 40%;
    margin: auto;
    display:inline-block;

    img {
        display: inline;
        max-width: 200px;
    }

    p {
        margin-bottom: 1em;
    }

    div.notification-container {
        margin-top: 1em;
        padding-top: 10px; // matching the padding-bottom on the p.control elements
    }

    .heavy-icon{
        max-width: 160px;
        margin: 20px;
    }

    div.forgotten-password-container {
        a {
            color: $grey-light;
            font-size: 0.9em;
            text-decoration: underline;
        }
        margin-top: 1em;
    }
}

</style>
