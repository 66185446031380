<template>
<div class="tile is-parent is-vertical">
        <div v-if="message" class="columns is-vcentered">
            <div class="column is-child is-8 is-offset-2">
                <p class="notification is-warning">
                    <i class="fas fa-exclamation-triangle"></i> {{message}}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { EventBus } from '@/event-bus';
import ability from '@/ability.js';

export default {
    name: 'LionLogin',
    data () {
        return {
            loginUrl: process.env.VUE_APP_INDIGO_API_URL + '/lionlogin',
            key: this.$route.query.key,
            message: null
        };
    },
    created () {
        if (!this.key) {
            this.message = 'no key found in querystring';
            return;
        }

        const lionLoginUrl = this.loginUrl + '?key=' + this.key;

        axios.post(lionLoginUrl)
            .then(response => {
                localStorage.setItem('jwt', response.data.token);
                localStorage.setItem('userId', response.data.userId);
                localStorage.setItem('email', response.data.email);
                localStorage.setItem('role', response.data.role);
                // update abilities for appropriate role after login
                this.$ability.update(ability[response.data.role].rules);

                if (localStorage.getItem('jwt') !== null) {
                    EventBus.$emit('loggedIn', true);
                    this.$router.push(window.sessionStorage.getItem('nextUrl') || '/');
                }
            })
            .catch(error => {
                if (error.response && error.response.data.message) {
                    this.message = error.response.data.message;
                } else {
                    this.message = 'Unexpected error: ' + error.message + ' - Please contact WW App Support.';
                }
            });
    }
};
</script>

<style lang="scss">
.tile.is-ancestor {
    flex-wrap:wrap;
}
p.control {
    padding-bottom: 10px;
}
</style>
